import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Alert } from "react-bootstrap";

const Config = ({ files, config, onConfigChange }) => {
  const [recommendedFirmware, setRecommendedFirmware] = useState(
    config?.recommended_firmware_version || ""
  );
  const [changed, setChanged] = useState(false);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    setRecommendedFirmware(config?.recommended_firmware_version);
  }, [config]);

  const handleFirmwareVersionChange = (e) => {
    setRecommendedFirmware(e.target.value);
    if (e.target.value !== config?.recommended_firmware_version) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  };

  const handleFirmwareVersionSubmit = (e) => {
    e.preventDefault();
    let formBody = [];
    formBody.push(
      encodeURIComponent("value") +
        "=" +
        encodeURIComponent(recommendedFirmware)
    );
    formBody = formBody.join("&");

    fetch(
      `${process.env.REACT_APP_API_URL}/config/recommended_firmware_version?${formBody}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            setChanged(false);
            setAlert({
              type: "success",
              message: "Firmware version updated successfully",
            });
            onConfigChange(data);
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setAlert({
          type: "danger",
          message: "Error updating firmware version",
        });
      });
  };

  return (
    <Row>
      <Col xs={{span: 4, offset: 4}} style={{padding: "10px"}}>
        {alert && (
          <Alert variant={alert.type} onClose={() => setAlert(null)} dismissible>
            {alert.message}
          </Alert>
        )}
      </Col>
      <Col
        xs={{ span: 3, offset: 1 }}
        style={{
          backgroundColor: "rgb(0,0,0,0.2)",
          padding: "20px",
          borderRadius: "0 0 0 20px",
        }}
      >
        <Row>
          <h3>Config</h3>
        </Row>
        <Row>
          <Form>
            <Row>
                  <Form.Label>Recommended Firmware:</Form.Label>
              <Col>
                <Form.Group className="mb-3" controlId="recommendedFirmware">
                  <Form.Select
                    aria-label="Select recommended firmware"
                    value={recommendedFirmware}
                    onChange={handleFirmwareVersionChange}
                  >
                    <option disabled value="">
                      Select firmware
                    </option>
                    {files.map((file) => (
                      <option key={file.version} value={file.version}>
                        {file.version}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
          {changed && (
            <Button
              variant="primary"
              type="submit"
              onClick={handleFirmwareVersionSubmit}
            >
              Submit
            </Button>
          )}
              </Col>
            </Row>
          </Form>
        </Row>
      </Col>
    </Row>
  );
};

export default Config;
