import React, { useEffect, useState } from "react";
import DevicesTable from "./DevicesTable";
import LoginForm from "./Login";
import Spinner from 'react-bootstrap/Spinner';
import Config from "./Config";

function App() {
  const [loading, setLoading] = useState(true);
  const [authorized, setAuthorized] = useState(false);
  const [devices, setDevices] = useState([]);
  const [updateFiles, setUpdateFiles] = useState([]);
  const [config, setConfig] = useState({});

  const token = localStorage.getItem('token') || '';

  useEffect(() => {
    Promise.all([
      fetch(`${process.env.REACT_APP_API_URL}/devices/`, {
        headers: { Authorization: `Bearer ${token}` }
      }),
      fetch(`${process.env.REACT_APP_API_URL}/files/`, {
        headers: { Authorization: `Bearer ${token}` }
      }),
      fetch(`${process.env.REACT_APP_API_URL}/config/`, {
        headers: { Authorization: `Bearer ${token}` }
      })
    ]).then(([devicesResponse, filesResponse, configResponse]) => {
      if (devicesResponse.status === 401 || filesResponse.status === 401 || configResponse.status === 401) {
        console.log("Device Unauthorized");
        setAuthorized(false);
      }
      if (devicesResponse.ok && filesResponse.ok && configResponse.ok) {
        devicesResponse
          .json()
          .then((data) => {
            setDevices(data);
          })
          .catch((error) => console.log(error));
        filesResponse
          .json()
          .then((data) => {
            setUpdateFiles(data);
          })
          .catch((error) => console.log(error));
        configResponse
          .json()
          .then((data) => {
            setConfig(data);
          })
          .catch((error) => console.log(error));
        setAuthorized(true);
      }
      setLoading(false);
    });
  }, []);

  const handleVersionChange = (deviceId, selectedVersion) => {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.getItem('token')}` },
      body: JSON.stringify({ recommended_firmware_version: selectedVersion }),
    };
    fetch(
      `${process.env.REACT_APP_API_URL}/devices/${deviceId}`,
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            setDevices(
              devices.map((device) => {
                if (device.device_id === deviceId) {
                  return data;
                }
                return device;
              })
            );
          });
        }
      })
      .catch((error) => console.log(error));
  };

  const handleConfigChange = (newConfig) => {
    // New config is a key value pair. Need to replace value for given key in config
    setConfig({
      ...config,
      ...{ [newConfig.name]: newConfig.value }
    });
  }

  if (loading) {
    return (
      <div className="App">
        <div style={{ margin: "25px" }}>
          <Spinner animation="border" role="status" />
          <span style={{ margin: "10px" }}>
            Loading...
          </span>
        </div>
      </div>
    );
  }

  if (!authorized) {
    return (
      <div className="App">
        <div style={{ margin: "25px" }}>
          <LoginForm />
        </div>
      </div>
    );
  }

  return (
    <div className="App">
      <div>
        <Config files={updateFiles} config={config} onConfigChange={handleConfigChange} />
      </div>
      <div style={{ margin: "25px" }}>
        <DevicesTable
          devices={devices}
          updateFiles={updateFiles}
          onVersionChange={handleVersionChange}
          config={config}
        />
      </div>
    </div>
  );
}

export default App;
