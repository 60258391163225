import React, { useState, useEffect } from 'react';
import { Table, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';

const TooltipedTime = ({ time }) => {
    const tzd = new Date().getTimezoneOffset();  // Timezone difference in minutes
    const tz_time = moment(time).subtract(tzd, 'minutes').format('YYYY-MM-DD HH:mm:ss');  // Subtracting timezone difference to get local time
    const renderTooltip = (props) => (
      <Tooltip id="tooltip-id" {...props}>
        {tz_time}
      </Tooltip>
    );

    return (
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        <span>
          {moment(tz_time).fromNow()}
        </span>
      </OverlayTrigger>
    );
  }

const DevicesTable = ({ devices, updateFiles, onVersionChange, config }) => {
  const [localDevices, setLocalDevices] = useState([]);
  const [recommendedFirmware, setRecommendedFirmware] = useState(config?.recommended_firmware_version || '');

  useEffect(() => {
    setRecommendedFirmware(config?.recommended_firmware_version);
  }, [config]);

  useEffect(() => {
    setLocalDevices(devices);
  }, [devices]);

    const handleChange = (deviceId, selectedVersion) => {
        const updatedDevices = localDevices.map(device => {
            if (device.device_id === deviceId) {
                return {
                    ...device,
                    recommended_firmware_version: selectedVersion
                };
            }
            return device;
        });
        setLocalDevices(updatedDevices);
    };

    const onConfirm = (deviceId) => {
        const device = localDevices.find(d => d.device_id === deviceId);
        onVersionChange(device.device_id, device.recommended_firmware_version);
    }

    return (
        <div>
            <h1>Devices ({localDevices.length})</h1>
            <Table striped bordered hover size="sm" className="text-center">
                <thead>
                    <tr>
                        <th>Device ID</th>
                        <th>Current firmware</th>
                        <th>Firmware to update</th>
                        <th>Last firmware info request</th>
                        <th>Last update file request</th>
                        <th>Created at</th>
                        <th>Modified at</th>
                        <th>Last seen</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {localDevices.map(device => (
                        <tr
                            key={device.device_id}
                        >
                            <td>{device.device_id}</td>
                            <td style={{ backgroundColor: device.firmware_version === (devices.filter(d => d.device_id === device.device_id)[0].recommended_firmware_version === "recommended" ? recommendedFirmware : devices.filter(d => d.device_id === device.device_id)[0].recommended_firmware_version ) ? 'lightgreen' : 'lightcoral' }}>
                              {device.firmware_version}
                            </td>
                            <td>
                                <Form.Select
                                    value={device.recommended_firmware_version || ''}
                                    onChange={(e) =>
                                        handleChange(device.device_id, e.target.value)
                                    }
                                >
                                    <option disabled value="">Select firmware</option>
                                    <option value="recommended">Recommended ({recommendedFirmware})</option>
                                    {updateFiles.map(file => (
                                        <option key={file.version} value={file.version}>
                                            {file.version}
                                        </option>
                                    ))}
                                </Form.Select>
                            </td>
                            <td><TooltipedTime time={device.device_firmware_file_requested_at} /></td>
                            <td>{device.device_recommended_version_requested_at ? (<TooltipedTime time={device.device_recommended_version_requested_at}/>) : 'N/A'}</td>
                            <td><TooltipedTime time={device.first_seen_at} /></td>
                            <td><TooltipedTime time={device.updated_at} /></td>
                            {/* Last seen is a most recent time from device_firmware_file_requested_at and device_recommended_version_requested_at */}
                            <td><TooltipedTime time={moment.max([moment(device.device_firmware_file_requested_at || 0), moment(device.device_recommended_version_requested_at || 0)])} /></td>
                            <td>
                              { device.recommended_firmware_version !== devices.filter(d => d.device_id === device.device_id)[0].recommended_firmware_version &&
                                <Button variant="primary" onClick={() => onConfirm(device.device_id)}>Confirm</Button>
                              }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default DevicesTable;